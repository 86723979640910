<span mat-dialog-title>{{ 'selectTemplate' | translate }}</span>

<saf-filter-panel (filterChange)="applyFilters($event)">
  <saf-hazard-assessment-template-category-filter slot="firstRow" class="twoFilter"></saf-hazard-assessment-template-category-filter>
  <saf-hazard-assessment-template-state-filter slot="firstRow" class="twoFilter"></saf-hazard-assessment-template-state-filter>
</saf-filter-panel>


<mat-dialog-content class="table-container">
  <table mat-table [dataSource]="dataSource" class="table" matSort>

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? toggleAll() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef>{{ 'status' | translate }}</th>
      <td mat-cell *matCellDef="let hazardAssessmentTemplate">
        <ng-container [ngSwitch]="hazardAssessmentTemplate.template.state">
          <mat-icon *ngSwitchCase="'N'" matTooltip="{{ 'stateInProgress' | translate }}">access_time</mat-icon>
          <mat-icon *ngSwitchCase="'P'" matTooltip="{{ 'stateEditorialAudit' | translate }}">visibility</mat-icon>
          <mat-icon *ngSwitchCase="'C'" matTooltip="{{ 'stateSafetyOfficerAudit' | translate }}">visibility</mat-icon>
          <mat-icon *ngSwitchCase="'Y'" matTooltip="{{ 'stateReleased' | translate }}">done</mat-icon>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="name">{{ 'name' | translate }}</th>
      <td mat-cell *matCellDef="let hazardAssessmentTemplate" [matTooltip]="hazardAssessmentTemplate.template.title">{{ hazardAssessmentTemplate.template.title }}</td>
    </ng-container>

    <ng-container matColumnDef="categories">
      <th mat-header-cell *matHeaderCellDef>{{ 'categories' | translate }}</th>
      <td mat-cell *matCellDef="let hazardAssessmentTemplate" [matTooltip]="getCategoryNames(hazardAssessmentTemplate)">{{ getCategoryNames(hazardAssessmentTemplate) }}</td>
    </ng-container>

    <ng-container matColumnDef="hazards">
      <th mat-header-cell *matHeaderCellDef>{{ 'hazards' | translate }}</th>
      <td mat-cell *matCellDef="let hazardAssessmentTemplate">{{ hazardAssessmentTemplate.numberOfHazards }}</td>
    </ng-container>

    <ng-container matColumnDef="measures">
      <th mat-header-cell *matHeaderCellDef>{{ 'measures' | translate }}</th>
      <td mat-cell *matCellDef="let hazardAssessmentTemplate">{{ hazardAssessmentTemplate.numberOfMeasures }}</td>
    </ng-container>

    <ng-container matColumnDef="used">
      <th mat-header-cell *matHeaderCellDef>{{ 'countUsed' | translate }}</th>
      <td mat-cell *matCellDef="let hazardAssessmentTemplate">{{ hazardAssessmentTemplate.numberOfUses }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
    <tr mat-row class="selectable" *matRowDef="let row; columns: tableColumns;" (click)="onClickRow(row)"></tr>
  </table>
</mat-dialog-content>

<mat-dialog-content>
  <mat-divider></mat-divider>
</mat-dialog-content>

<mat-dialog-content>
  <div class="pagination-toolbar">
    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalCount" [showFirstLastButtons]="true"></mat-paginator>
  </div>
</mat-dialog-content>

<saf-loading-overlay *ngIf="loading"></saf-loading-overlay>

<mat-dialog-actions align="end" *ngIf="multiple">
  <button class="administration deleteButtonHover" color="primary" mat-raised-button (click)="cancel()">{{ 'cancel' | translate }}</button>
  <button class="administration saveButtonHover" mat-raised-button (click)="submit()" color="primary">{{ 'save' | translate }}</button>
</mat-dialog-actions>
