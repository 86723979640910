import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ChecklistDropDownDialogComponent} from '@shared/components/checklist-drop-down-dialog/checklist-drop-down-dialog.component';
import {MaterialModule} from '@shared/material.module';
import {EmptyMessageModule} from '@shared/components/empty-message/empty-message.module';
import {LoadingSpinnerModule} from '@shared/components/loading-spinner/loading-spinner.module';
import {LoadingOverlayModule} from '@shared/components/loading-overlay/loading-overlay.module';
import {ExtendedModule} from '@angular/flex-layout';
import {PipesModule} from '@shared/pipes/pipes.module';



@NgModule({
  declarations: [ChecklistDropDownDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    EmptyMessageModule,
    LoadingSpinnerModule,
    LoadingOverlayModule,
    ExtendedModule,
    PipesModule,
  ],
  exports: [ChecklistDropDownDialogComponent]
})
export class ChecklistDropDownDialogModule { }
