<form [formGroup]="form" ngxsForm="terminalPage.addExternalEmployeeForm" (ngSubmit)="onSubmit()">
  <p class="description">{{ 'pleaseEnterYourPersonalData' | translate }}</p>
  <div class="block-fields">
    <saf-external-company-select [ngClass]="{ 'half-width': !terminal.simpleView, 'full-width': terminal.simpleView }" formControlName="externalCompanyId"></saf-external-company-select>

    <ng-container *ngIf="!terminal.simpleView">
      <saf-language-select class="half-width" formControlName="languageId" required></saf-language-select>
      <saf-salutation-select class="half-width" formControlName="salutation"></saf-salutation-select>
      <mat-form-field class="half-width" appearance="outline">
        <mat-label>{{ 'title' | translate }}</mat-label>
        <input type="text" matInput formControlName="title" autocomplete="off">
      </mat-form-field>
    </ng-container>

    <mat-form-field class="half-width" appearance="outline">
      <mat-label>{{ 'firstName' | translate }}</mat-label>
      <input type="text" matInput formControlName="firstName" autocomplete="disabled" required>
    </mat-form-field>
    <mat-form-field class="half-width" appearance="outline">
      <mat-label>{{ 'lastName' | translate }}</mat-label>
      <input type="text" matInput formControlName="name" autocomplete="disabled" required>
    </mat-form-field>

    <ng-container *ngIf="!terminal.simpleView">
      <mat-form-field class="half-width" appearance="outline">
        <mat-label>{{ 'username' | translate }}</mat-label>
        <input type="text" matInput formControlName="login" autocomplete="disabled">
      </mat-form-field>
      <mat-form-field class="half-width" appearance="outline">
        <mat-label>{{ 'password' | translate }}</mat-label>
        <input type="password" matInput formControlName="password" autocomplete="disabled">
      </mat-form-field>
      <mat-form-field class="half-width" appearance="outline">
        <mat-label>{{ 'emailAddress' | translate }}</mat-label>
        <input type="email" matInput formControlName="email" autocomplete="disabled">
      </mat-form-field>
      <mat-form-field class="half-width" appearance="outline">
        <mat-label>{{ 'personalNumber' | translate }}</mat-label>
        <input type="text" matInput formControlName="employeeNumber" autocomplete="disabled">
      </mat-form-field>
    </ng-container>

    <!--  Hidden new-password input field to disable autocomplete  -->
    <input type="password" autocomplete="new-password" class="hidden">
  </div>
  <div fxLayoutAlign="end">
    <button type="submit" mat-raised-button color="primary">{{ 'next' | translate }}</button>
  </div>
</form>
