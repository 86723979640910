import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import {Cacheable, CacheBuster} from 'ts-cacheable';
import { DataResponse, ListResponse, SuccessResponse } from '@shared/models/response.model';
import { ExternalCompany } from '@shared/models/external-company.model';
import { ExternalCompanyDetails } from '@shared/models/external-company-details.model';

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class ExternalCompanyService {

  constructor(private api: ApiService) {}

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getAll(params?: object): Observable<ListResponse<ExternalCompany>> {
    return this.api.get(`/external/companies`, params);
  }

  getById(id): Observable<ExternalCompany> {
    return this.api.get(`/external/company/${id}`);
  }

  getDetails(externalCompanyId): Observable<ExternalCompanyDetails> {
    return this.api.get(`/external/companyDetail/${externalCompanyId}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<ExternalCompany>> {
    return this.api.post(`/external/company`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  createDetails(data: object): Observable<DataResponse<ExternalCompanyDetails>> {
    return this.api.post(`/external/companyDetail`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<ExternalCompany>> {
    return this.api.put(`/external/company/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  updateDetails(id: number, data: object): Observable<DataResponse<ExternalCompanyDetails>> {
    return this.api.put(`/external/companyDetail/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/external/company/${id}`);
  }

  getExportURL(filter: object, jwt: string) {
    return `${this.api.apiURL}/external/companies/export?filter=${JSON.stringify(filter)}&jwt=${jwt}`;
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
