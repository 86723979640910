import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ApiService} from '@shared/services/api.service';
import {DataResponse, ListResponse, SuccessResponse} from '@shared/models/response.model';
import {Cacheable, CacheBuster} from 'ts-cacheable';
import {AccidentListItem} from '@shared/models/accident-list-item.model';
import {Accident} from '@shared/models/accident.model';
import {AccidentDetails} from '@shared/models/accident-details.model';
import {AccidentDowntime} from '@shared/models/accident-downtime.model';
import {AccidentInjury} from '../models/accidentInjury.model';
import {AccidentInjuryDetailModel} from '../models/accident-injury-detail.model';
import {AccidentPreReportModel} from '../models/accidentPreReport.model';

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class AccidentService {

  constructor(private api: ApiService) {
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getList(params?: object): Observable<ListResponse<AccidentListItem>> {
    return this.api.get(`/accident-list`, params);
  }

  getById(id): Observable<Accident> {
    return this.api.get(`/accident/${id}`);
  }

  getPrintURL(route: string, accidentId: number) {
    return `${this.api.apiURL}${route}?meId=${accidentId}`;
  }

  getPrintEmptyInstant(jwt: string) {
    return `${this.api.apiURL}/medical/print?jwt=${jwt}`;
  }

  getPrintInstant(accidentId: number, jwt: string) {
    return `${this.api.apiURL}/medical/${accidentId}/print?jwt=${jwt}`;
  }

  getPrintAccidentReport(accidentId: number, jwt: string) {
    return `${this.api.apiURL}/medical/${accidentId}/printAccidentReport?jwt=${jwt}`;
  }

  getPrintAccidentPostEditing(accidentId: number, jwt: string) {
    return `${this.api.apiURL}/medical/${accidentId}/printAccidentPostEditing?jwt=${jwt}`;
  }

  getPrintUnfallbearbeitung(route: string, accidentId: number) {
    return `${this.api.apiURL}${route}?accidentId=${accidentId}`;
  }

  getDetails(id: number): Observable<AccidentDetails> {
    return this.api.get(`/accidentDetail/${id}`);
  }

  sendReport(id: number): Observable<SuccessResponse> {
    return this.api.put(`/accidentMail/${id}`);
  }

  getInjuryDetails(params?: object): Observable<ListResponse<AccidentInjury>> {
    return this.api.get(`/medical/accidentInjuries`, params);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  createAccidentInjury(params: object): Observable<DataResponse<AccidentInjuryDetailModel>> {
    return this.api.post(`/medical/accidentInjury`, params);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  updateAccidentInjury(id: number, data: object): Observable<DataResponse<AccidentInjuryDetailModel>> {
    return this.api.put(`/medical/accidentInjury/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  deleteAccidentInjury(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/medical/accidentInjury/${id}`);
  }

  createDetails(data: object): Observable<DataResponse<AccidentDetails>> {
    return this.api.post(`/accidentDetail`, data);
  }


  updateDetails(id: number, data: object): Observable<DataResponse<AccidentDetails>> {
    return this.api.put(`/accidentDetail/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<Accident>> {
    return this.api.post(`/accident`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<Accident>> {
    return this.api.put(`/accident/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/accident/${id}`);
  }

  calculateDowntimeDays(id, data?: object): Observable<AccidentDowntime> {
    return this.api.put(`/accident/${id}/calculateDowntime`, data);
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getOneThousandAnalyse(params?: object): Observable<any> {
    return this.api.get(`/medical/oneThousandAnalyse`, params);
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getOneMillionAnalyse(params?: object): Observable<any> {
    return this.api.get(`/medical/oneMillionAnalyse`, params);
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getNearAccident(params?: object): Observable<any> {
    return this.api.get(`/medical/nearAccident`, params);
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getInjuryAnalyse(params?: object): Observable<any> {
    return this.api.get(`/medical/injuryAnalyse`, params);
  }


  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getSectionData(params?: object): Observable<any> {
    return this.api.get(`/medical/sectionData`, params);
  }

  getExportURL(filter: object, jwt: string) {
    return `${this.api.apiURL}/resources_project/guiExtDesktop/_medical/analyse/analyseExcelExport.php?dateFrom=${filter['dateFrom']}&dateTill=${filter['dateTill']}&sections=${JSON.stringify(filter['sections'])}&jwt=${jwt}`;
  }

  getAccidentExportURL(filter: object, jwt: string) {
    return `${this.api.apiURL}/accidents/export?filter=${JSON.stringify(filter)}&jwt=${jwt}`;
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  creatAccidentPreReport(data: object): Observable<DataResponse<AccidentPreReportModel>> {
    return this.api.post(`/accidentPreReport`, data);
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getAccidentPreReportList(params?: object): Observable<ListResponse<AccidentPreReportModel>> {
    return this.api.get(`/accidentPreReports`, params);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  deleteAccidentPreReport(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/accidentPreReport/${id}`);
  }

  getAccidentPreReportById(id): Observable<AccidentPreReportModel> {
    return this.api.get(`/accidentPreReport/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  updateAccidentPreReport(id: number, data: object): Observable<DataResponse<AccidentPreReportModel>> {
    return this.api.put(`/accidentPreReport/${id}`, data);
  }
  static clearCache() {
    cacheNotifier.next();
  }
}
