<mat-card class="card" *ngIf="terminal$ | async">
  <mat-card-header class="no-text-margin">
    <h3>{{ (terminal$ | async).name }}</h3>
    <button *ngIf="currentActivity" mat-icon-button class="close-button" (click)="cancelActivity()">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content [ngSwitch]="currentView$ | async">

    <saf-terminal-page-select-language-view *ngSwitchCase="'select-language'"
                                            [terminal]="terminal$ | async"
                                            [activity]="activity$ | async"
                                            (viewCompleted)="onLanguageSelected()"></saf-terminal-page-select-language-view>

    <saf-terminal-page-add-external-company-view *ngSwitchCase="'add-external-company'"
                                          [terminal]="terminal$ | async"
                                          [activity]="activity$ | async"
                                          (viewCompleted)="onCompanyCreated()"></saf-terminal-page-add-external-company-view>

    <saf-terminal-page-add-external-employee-view *ngSwitchCase="'add-external-employee'"
                                           [terminal]="terminal$ | async"
                                           [activity]="activity$ | async"
                                           (viewCompleted)="onEmployeeCreated()"></saf-terminal-page-add-external-employee-view>

    <saf-terminal-page-confirm-external-employee-view *ngSwitchCase="'confirm-external-employee'"
                                      [terminal]="terminal$ | async"
                                      [activity]="activity$ | async"
                                      (viewCompleted)="onEmployeeConfirmed()"></saf-terminal-page-confirm-external-employee-view>

    <saf-terminal-page-schooling-view *ngSwitchCase="'schooling'"
                                      [terminal]="terminal$ | async"
                                      [activity]="activity$ | async"
                                      (viewCompleted)="onSchoolingDone()"></saf-terminal-page-schooling-view>

    <saf-terminal-page-polling-view *ngSwitchCase="'poll-activity'"></saf-terminal-page-polling-view>

    <saf-loading-overlay *ngIf="loading$ | async"></saf-loading-overlay>
  </mat-card-content>
</mat-card>

<saf-loading-overlay *ngIf="!(terminal$ | async)" [hasBackdrop]="false"></saf-loading-overlay>
