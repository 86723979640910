import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {SearchFilterComponent} from '@shared/components/search-filter/search-filter.component';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TranslationService} from '@shared/services/translation.service';
import {DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS} from '@shared/utils/utils';
import {merge} from 'rxjs';
import {tap} from 'rxjs/operators';
import {
  HazardAssessmentTemplateDialogComponent
} from '@shared/components/hazard-assessment-template-dialog/hazard-assessment-template-dialog.component';
import {HazardAssessmentTemplateCategory} from '@shared/models/hazard-assessment-template-category.model';
import {HazardAssessmentTemplateCategoryService} from '@shared/services/hazard-assessment-template-category.service';

export interface HazardAssessmentTemplateCategoryDialogConfig {
  multiple: boolean;
  selected: HazardAssessmentTemplateCategory[];
}

@Component({
  selector: 'saf-hazard-assessment-template-category-select-dialog',
  templateUrl: './hazard-assessment-template-category-select-dialog.component.html',
  styleUrls: ['./hazard-assessment-template-category-select-dialog.component.scss']
})
export class HazardAssessmentTemplateCategorySelectDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(SearchFilterComponent) searchFilter: SearchFilterComponent;

  public tableColumns = ['select', 'name'];
  public dataSource = new MatTableDataSource();
  public loading = true;
  public totalCount = 0;
  public multiple = false;
  public drawerFilterParams: any = {};
  public searchFilterParams: any = {};
  public selection: SelectionModel<HazardAssessmentTemplateCategory>;
  public selected = null;

  public trackById = (item) => item.id;

  constructor(
    public dialogRef: MatDialogRef<HazardAssessmentTemplateDialogComponent, HazardAssessmentTemplateCategory | HazardAssessmentTemplateCategory[] | null>,
    private hazardAssessmentTemplateCategoryService: HazardAssessmentTemplateCategoryService,
    private translationService: TranslationService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public config: HazardAssessmentTemplateCategoryDialogConfig,
    @Inject(PAGE_SIZE_OPTIONS) public pageSizeOptions,
    @Inject(DEFAULT_PAGE_SIZE) public pageSize,
  ) {
    this.multiple = this.config.multiple;
    this.selected = this.config.selected;

    if (this.multiple) {
      this.selection = new SelectionModel<HazardAssessmentTemplateCategory>(this.multiple, this.selected);
      if (this.selected) {
      }
    } else {
      this.selection = new SelectionModel<HazardAssessmentTemplateCategory>(false, this.selected);
    }
  }

  ngOnInit(): void {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page).subscribe(() => this.loadTable());
  }

  ngAfterViewInit() {
    this.loadTable();
    this.changeDetectorRef.detectChanges();
  }

  loadTable() {
    const params = {
      filter: {
        ...this.searchFilterParams,
        ...this.drawerFilterParams,
      },
      sort: this.sort.direction ? this.sort.active : '',
      dir: this.sort.direction,
      start: localStorage.getItem('start') ? localStorage.getItem('start') : this.paginator.pageIndex * this.paginator.pageSize,
      limit: this.paginator.pageSize,
    };

    this.loading = true;
    localStorage.removeItem('start');

    this.hazardAssessmentTemplateCategoryService.getList(params)
      .pipe(tap(() => this.loading = false))
      .subscribe((response) => {
        this.dataSource.data = response.data;
        this.totalCount = response.total;
      });
  }

  applyDrawerFilters(filterParams) {
    this.drawerFilterParams = filterParams;
    this.paginator.pageIndex = 0;
    this.loadTable();
  }

  closeDialog(e, hazardAssessmentTemplateCategory?: HazardAssessmentTemplateCategory) {
    this.dialogRef.close(hazardAssessmentTemplateCategory);
  }

  select(item) {
    this.dialogRef.close(item);
  }

  cancel() {
    this.dialogRef.close(null);
  }

  submit() {
    this.dialogRef.close(this.selection.selected);
  }

  close(result = null) {
    this.dialogRef.close(result);
  }

  isAllSelected() {
    return this.selection.selected.length === this.dataSource.data.length;
  }

  isSelected(row: HazardAssessmentTemplateCategory) {
    return !!(this.selection.selected.find(hazardAssessmentTemplateCategory => hazardAssessmentTemplateCategory.id === row.id));
  }

  toggleAll() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach((row: HazardAssessmentTemplateCategory) => this.selection.select(row));
    }
  }

  selectedRow(row: HazardAssessmentTemplateCategory, e) {
    if (this.multiple) {
      if (e.checked) {
        this.selection.toggle(row);
      } else {
        const selection = this.selection.selected;
        this.selection.clear();
        selection.forEach(selected => {
          if (selected.id !== row.id) {
            this.selection.select(selected);
          }
        });
      }
    } else {
      if (e.checked) {
        this.selection.clear();
        this.selection.toggle(row);
      } else {
        this.selection.clear();
      }
    }
  }

  reloadTable() {
    HazardAssessmentTemplateCategoryService.clearCache();
    this.loadTable();
  }
}
