import { Component, HostBinding, Input } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'saf-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss']
})
export class LoadingOverlayComponent {
  @HostBinding('class.has-backdrop')
  @Input()
  get hasBackdrop(): boolean { return this._hasBackdrop; }
  set hasBackdrop(value: boolean) { this._hasBackdrop = coerceBooleanProperty(value); }
  private _hasBackdrop = true;
}
