import { NgModule } from '@angular/core';
import { TerminalComponent } from './pages/terminal/terminal.component';
import { SharedModule } from '@shared/shared.module';
import { TerminalRoutingModule } from '@terminal/terminal-routing.module';
import { TerminalLoginComponent } from './pages/terminal-login/terminal-login.component';
import { TerminalPageComponent } from './pages/terminal-page/terminal-page.component';
import { TerminalPagePollingViewComponent } from './components/terminal-page-polling-view/terminal-page-polling-view.component';
import { TerminalPageSelectLanguageViewComponent } from './components/terminal-page-select-language-view/terminal-page-select-language-view.component';
import { TerminalPageAddExternalEmployeeViewComponent } from './components/terminal-page-add-external-employee-view/terminal-page-add-external-employee-view.component';
import { TerminalPageAddExternalCompanyViewComponent } from './components/terminal-page-add-external-company-view/terminal-page-add-external-company-view.component';
import { TerminalPageSchoolingViewComponent } from './components/terminal-page-schooling-view/terminal-page-schooling-view.component';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsModule } from '@ngxs/store';
import { TerminalPageState } from '@terminal/store/terminal-page/terminal-page.state';
import { TerminalPageConfirmExternalEmployeeViewComponent } from './components/terminal-page-confirm-external-employee-view/terminal-page-confirm-external-employee-view.component';

@NgModule({
  imports: [
    SharedModule,
    TerminalRoutingModule,
    NgxsModule.forFeature([
      TerminalPageState,
    ]),
    NgxsFormPluginModule,
  ],
  declarations: [
    TerminalComponent,
    TerminalLoginComponent,
    TerminalPageComponent,
    TerminalPageSelectLanguageViewComponent,
    TerminalPagePollingViewComponent,
    TerminalPageAddExternalEmployeeViewComponent,
    TerminalPageAddExternalCompanyViewComponent,
    TerminalPageSchoolingViewComponent,
    TerminalPageConfirmExternalEmployeeViewComponent,
  ],
})
export class TerminalModule {}
