import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Employee } from '@shared/models/employee.model';
import { ApiService } from '@shared/services/api.service';
import {Cacheable, CacheBuster} from 'ts-cacheable';
import { DataResponse, SuccessResponse, ListResponse } from '@shared/models/response.model';
import { EmployeeListItem } from '@shared/models/employee-list-item.model';
import {UserDetails} from '@shared/models/user-details.model';

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(private api: ApiService) {}

	@Cacheable({
		cacheBusterObserver: cacheNotifier
	})
	getList(params?: object): Observable<ListResponse<EmployeeListItem>> {
		return this.api.get(`/employee-list`, params);
	}

	getById(id: number): Observable<DataResponse<Employee>> {
		return this.api.get(`/employee/${id}`);
	}

	getExportURL(filter: object, jwt: string) {
		return `${this.api.apiURL}/employees/export?filter=${JSON.stringify(filter)}&jwt=${jwt}`;
	}



	getReportPrintURL(employeeId: number, jwt: string) {
		return `${this.api.apiURL}/employeeReport/${employeeId}?jwt=${jwt}`;
	}

	@CacheBuster({
		cacheBusterNotifier: cacheNotifier
	})
	create(data: object): Observable<DataResponse<Employee>> {
		return this.api.post(`/employee`, data);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheNotifier
	})
	update(id: number, data: object): Observable<DataResponse<Employee>> {
		return this.api.put(`/employee/${id}`, data);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheNotifier
	})
	createDetail(data: object): Observable<DataResponse<UserDetails>> {
		return this.api.post(`/userDetails`, data);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheNotifier
	})
	updateDetail(userId: number, data: object): Observable<DataResponse<UserDetails>> {
		return this.api.put(`/userDetails/${userId}`, data);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheNotifier
	})
	delete(id: number): Observable<SuccessResponse> {
		return this.api.delete(`/employee/${id}`);
	}

	@CacheBuster({
		cacheBusterNotifier: cacheNotifier
	})
	bulkChangeUsers(employeeIds: number, changes: object): Observable<SuccessResponse> {
		return this.api.put(`/employeeBulkChange`, { employeeIds: employeeIds, changes });
	}

	static clearCache() {
	cacheNotifier.next();
	}
}
