import { NgModule } from '@angular/core';
import { MaterialModule } from '@shared/material.module';
import { LoadingOverlayComponent } from '@shared/components/loading-overlay/loading-overlay.component';

@NgModule({
  imports: [
    MaterialModule,
  ],
  declarations: [
    LoadingOverlayComponent,
  ],
  exports: [
    LoadingOverlayComponent,
  ],
})
export class LoadingOverlayModule {}
