<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <p class="description">{{ 'pleaseConfirmYourPersonalData' | translate }}</p>
  <div class="block-fields">
    <mat-form-field class="half-width" appearance="outline">
      <mat-label>{{ 'companyName' | translate }}</mat-label>
      <input type="text" matInput formControlName="companyName" autocomplete="disabled" required readonly>
    </mat-form-field>
    <mat-form-field class="half-width" appearance="outline">
      <mat-label>{{ 'firstName' | translate }}</mat-label>
      <input type="text" matInput formControlName="firstName" autocomplete="disabled" required readonly>
    </mat-form-field>
    <mat-form-field class="half-width" appearance="outline">
      <mat-label>{{ 'lastName' | translate }}</mat-label>
      <input type="text" matInput formControlName="name" autocomplete="disabled" required readonly>
    </mat-form-field>
  </div>
  <div fxLayoutAlign="end">
    <button class="administration saveButtonHover" type="submit" mat-raised-button color="primary">{{ 'save' | translate }}</button>
  </div>
</form>
<saf-loading-overlay *ngIf="loading"></saf-loading-overlay>
