<mat-card class="card">
  <mat-card-content>
    <h1 class="title">{{ 'error404' | translate }}</h1>
    <p class="description">{{ 'pageNotFoundText' | translate }}</p>
    <div fxLayoutAlign="center">
      <a mat-raised-button color="primary" routerLink="/">{{ 'goBackToHomepage' | translate }}</a>
    </div>
  </mat-card-content>
</mat-card>


