import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { FilterChange } from '@shared/models/filter-change.model';
import { HazardAssessmentTemplateStateSelectComponent } from '@shared/components/hazard-assessment-template-state-select/hazard-assessment-template-state-select.component';
import { FilterComponent } from '@shared/components/filter/filter.component';

@Component({
  selector: 'saf-hazard-assessment-template-state-filter',
  templateUrl: './hazard-assessment-template-state-filter.component.html',
  styleUrls: ['./hazard-assessment-template-state-filter.component.scss'],
  host: { 'class': 'filter' },
  providers: [{
    provide: FilterComponent,
    useExisting: forwardRef(() => HazardAssessmentTemplateStateFilterComponent),
  }]
})
export class HazardAssessmentTemplateStateFilterComponent {
  @ViewChild(HazardAssessmentTemplateStateSelectComponent) select: HazardAssessmentTemplateStateSelectComponent;
  @Output() valueChange = new EventEmitter<FilterChange>();
  @Output() valueReset = new EventEmitter<string>();
  @Input() filterParam: string = 'status';
  @Input() placeholder: string;

  onChange(status: boolean | null) {
    this.valueChange.emit({
      param: this.filterParam,
      value: status,
    });
  }

  clear() {
    this.select.clear();
    this.valueReset.emit(this.filterParam);
  }
}
