import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { DataResponse, SuccessResponse, ListResponse } from '@shared/models/response.model';
import { HazardAssessment } from '@shared/models/hazard-assessment.model';
import { HazardAssessmentDetails } from '@shared/models/hazard-assessment-details.model';
import { HazardAssessmentHistory } from '@shared/models/hazard-assessment-history.model';
import { InstructionListItem } from '@shared/models/instruction-list-item.model';

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class HazardAssessmentService {

  constructor(private api: ApiService) {}

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getList(params?: object): Observable<ListResponse<HazardAssessment>> {
    return this.api.get(`/hazardAssessments`, params);
  }

  getById(id: number): Observable<HazardAssessment> {
    return this.api.get(`/hazardAssessment/${id}`);
  }

  getHistory(hazardAssessmentId: number, params?: object): Observable<ListResponse<HazardAssessmentHistory>> {
    return this.api.get(`/hazardAssessment/${hazardAssessmentId}/history`, params);
  }

  getInstructions(hazardAssessmentId: number, params?: object): Observable<ListResponse<InstructionListItem>> {
    return this.api.get(`/hazardAssessment/${hazardAssessmentId}/instructions`, params);
  }

  getHazardAndMeasureTree(hazardAssessmentId: number, params?: object): Observable<any> {
    return this.api.get(`/hazardAssessment/${hazardAssessmentId}/hazardAndMeasurementTree`, params);
  }

  getDetails(hazardAssessmentId: number): Observable<HazardAssessmentDetails> {
    return this.api.get(`/hazardAssessmentDetails/${hazardAssessmentId}`);
  }

  getPrintURL(hazardAssessmentId: number, departmentId: number, legalAdvice: boolean, printAssessmentsLawList: boolean, printAssessmentsOfSubSections: boolean, diagonally: boolean, jwt: string): string {
    return `${this.api.apiURL}/resources_project/guiExtDesktop/_hazardAssessment/assessment/print/printAssessment.php?sectionId=${departmentId}&assessmentId=${hazardAssessmentId}&printLegalAdvice=${legalAdvice}&printAssessmentsOfSubSections=${printAssessmentsOfSubSections}&printAssessmentsLawList=${printAssessmentsLawList}&diagonally=${diagonally}&jwt=${jwt}`;
  }

  getPrintURL2(hazardAssessmentId: number, jwt: string) {
    return `${this.api.apiURL}/hazardAssessment/${hazardAssessmentId}/print?jwt=${jwt}`;
  }

  getExportURL(jwt: string) {
    return `${this.api.apiURL}/hazardAssessments/export?jwt=${jwt}`;
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<HazardAssessment>> {
    return this.api.post(`/hazardAssessment`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  copy(hazardAssessmentId: number, data: object): Observable<DataResponse<HazardAssessment>> {
    return this.api.post(`/hazardAssessment/copy/${hazardAssessmentId}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  createFromTemplate(templateId: number, data: object): Observable<DataResponse<HazardAssessment>> {
    return this.api.post(`/hazardAssessment/createFromTemplate/${templateId}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<HazardAssessment>> {
    return this.api.put(`/hazardAssessment/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/hazardAssessment/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  addToAssessment(data: object): Observable<SuccessResponse> {
    return this.api.post(`/classificationHazardTemplate/addToAssessment`, data );
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
