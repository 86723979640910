import {AfterViewInit, Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { tap } from 'rxjs/operators';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {HazardAssessmentTemplateCategoryService} from '@shared/services/hazard-assessment-template-category.service';
import {
  HazardAssessmentTemplateCategorySelectDialogComponent
} from '@shared/components/hazard-assessment-template-category-select-dialog/hazard-assessment-template-category-select-dialog.component';

@Component({
  selector: 'saf-hazard-assessment-template-category-select',
  templateUrl: './hazard-assessment-template-category-select.component.html',
  styleUrls: ['./hazard-assessment-template-category-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HazardAssessmentTemplateCategorySelectComponent),
      multi: true
    }
  ],
})
export class HazardAssessmentTemplateCategorySelectComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  _valueText: string = '';
  private hazardAssessmentTemplateCategory;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) { this._required = coerceBooleanProperty(value); }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled || this._loading; }
  set disabled(value: boolean) { this._disabled = coerceBooleanProperty(value); }
  public _disabled = false;

  @Input()
  get multiple(): boolean { return this._multiple; }
  set multiple(value: boolean) { this._multiple = coerceBooleanProperty(value); }
  private _multiple = false;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) { this._placeholder = value; }
  private _placeholder: string;

  @Input()
  get appearance(): MatFormFieldAppearance { return this._appearance; }
  set appearance(value: MatFormFieldAppearance) { this._appearance = value; }
  private _appearance: MatFormFieldAppearance = 'outline';

  @Input()
  get value(): any { return this._value; }
  set value(value: any) { this.writeValue(value); }
  private _value: any = null;

  @Input()
  get isFilter(): boolean { return this._isFilter; }
  set isFilter(value: boolean) { this._isFilter = coerceBooleanProperty(value); }
  private _isFilter = false;

  get loading(): boolean { return this._loading; }
  private _loading: boolean = false;

  get hasValue(): boolean {
    if (this.disabled === false) {
      return this.multiple ? this.value && this.value.length : this.value !== null;
    } else {
      return false;
    }
  }

  get hasNoValue(): boolean {
    if (this._valueText === '' || this.value === null || this.disabled === true ) {
      return true;
    }
  }

  // hazardAssessmentTemplateCategories: HazardAssessmentTemplateCategory[] = [];

  @Output() valueChange = new EventEmitter<any>();

  onChange: Function = () => {};
  onTouched: Function = () => {};

  constructor(
    private hazardAssessmentTemplateCategoryService: HazardAssessmentTemplateCategoryService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.value) {
      this.hazardAssessmentTemplateCategory = this.value;
      this._valueText = this.hazardAssessmentTemplateCategory.map((credential) => credential.name).join(', ');
    }
    this.value
      ? this.hazardAssessmentTemplateCategory = this.value
      : this.hazardAssessmentTemplateCategory = null;
    this.loadById(this.value);
  }

  loadById(id: number) {
    if (id) {
      this._loading = true;

      this.hazardAssessmentTemplateCategoryService.getById(id)
        .pipe(tap(() => this._loading = false))
        .subscribe((response) => {
          this._valueText = response.name;
          this.hazardAssessmentTemplateCategory = response;
        });
    }
  }

  openObservationTypeSelectDialog(e) {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'small-dialog',
      disableClose: true,
      data: {
        multiple: this.multiple,
        selected: this.hazardAssessmentTemplateCategory.length ? this.hazardAssessmentTemplateCategory : [this.hazardAssessmentTemplateCategory],
      }
    };
    this._loading = true;
    this.dialog.open(HazardAssessmentTemplateCategorySelectDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(tap(() => this._loading = false))
      .subscribe((result) => {
        if (result.length === 0) {
          this.value = null;
          this._valueText = '';
          this.hazardAssessmentTemplateCategory = null;
        } else {
          this.value = result.map(hazardAssessmentTemplateCategory => hazardAssessmentTemplateCategory.id)[0];
          this._valueText = result.map(hazardAssessmentTemplateCategory => hazardAssessmentTemplateCategory.name)[0];
          this.hazardAssessmentTemplateCategory = result;
        }
      });
  }

  registerOnChange(onChange: Function) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: Function) {
    this.onTouched = onTouched;
  }

  writeValue(value: any) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
    this.valueChange.emit(value);
  }

  clear() {
    this._valueText = '';
    this.hazardAssessmentTemplateCategory = [];
    this.value = null;
  }

  onClickClear(event) {
    this.clear();

    event.stopImmediatePropagation();
  }
}
