import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { HazardAssessmentTemplate } from '@shared/models/hazard-assessment-template.model';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ListResponse } from '@shared/models/response.model';
import { HazardAssessmentTemplateService } from '@shared/services/hazard-assessment-template.service';
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from '@shared/utils/utils';
import { HazardAssessmentService } from '@shared/services/hazard-assessment.service';

export interface HazardAssessmentTemplateDialogConfig {
  multiple: boolean;
  selected: any;
}

@Component({
  selector: 'saf-hazard-assessment-template-dialog',
  templateUrl: './hazard-assessment-template-dialog.component.html',
  styleUrls: ['./hazard-assessment-template-dialog.component.scss']
})
export class HazardAssessmentTemplateDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public tableColumns = ['state', 'name', 'categories', 'hazards', 'measures', 'used'];
  public dataSource = new MatTableDataSource();
  public loading = false;
  public totalCount = 0;
  public multiple = false;
  public selected = null;
  public selection: SelectionModel<HazardAssessmentTemplate>;

  constructor(
    public dialog: MatDialogRef<HazardAssessmentTemplateDialogComponent, HazardAssessmentTemplate | HazardAssessmentTemplate[] | null>,
    private hazardAssessmentTemplateService: HazardAssessmentTemplateService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private config: HazardAssessmentTemplateDialogConfig,
    @Inject(PAGE_SIZE_OPTIONS) public pageSizeOptions,
    @Inject(DEFAULT_PAGE_SIZE) public pageSize,
  ) {
    this.multiple = this.config.multiple;
    this.selected = this.config.selected;

    if (this.multiple) {
      this.selection = new SelectionModel<HazardAssessmentTemplate>(this.multiple, []);
      this.tableColumns.unshift('select');
    }
  }

  ngOnInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page).subscribe(() => this.loadTable());
  }

  ngAfterViewInit() {
    this.loadTable();
    this.changeDetectorRef.detectChanges();
  }

  loadTable(filter: object = {}) {
    const params = {
      ...filter,
      sort: this.sort.direction ? this.sort.active : '',
      dir: this.sort.direction,
      start: this.paginator.pageIndex * this.paginator.pageSize,
      limit: this.paginator.pageSize,
    };

    this.loading = true;

    return this.hazardAssessmentTemplateService.getList(params)
      .pipe(tap(() => this.loading = false))
      .subscribe((response: ListResponse) => {
        this.dataSource.data = response.data;
        this.totalCount = response.total;

        if (this.multiple && (this.selected && this.selected.length)) {
          this.selection.select(...response.data.filter((user) => this.selected.includes(user.id)));
        }
      });
  }

  reloadTable() {
    HazardAssessmentTemplateService.clearCache();
    this.loadTable();
  }

  applyFilters(filterParams) {
    this.loadTable(filterParams);
  }

  select(item) {
    this.dialog.close(item);
  }

  cancel() {
    this.dialog.close(null);
  }

  submit() {
    this.dialog.close(this.selection.selected);
  }

  isAllSelected() {
    return this.selection.selected.length === this.dataSource.data.length;
  }

  toggleAll() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach((row: HazardAssessmentTemplate) => this.selection.select(row));
    }
  }

  onClickRow(item: HazardAssessmentTemplate) {
    if (this.multiple) {
      this.selection.toggle(item);
    } else {
      this.select(item);
    }
  }

  getCategoryNames(hazardAssessmentTemplate: HazardAssessmentTemplate) {
    return hazardAssessmentTemplate.categories.map((category) => category.name).join(', ');
  }
}
