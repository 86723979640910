import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { TerminalService } from '@shared/services/terminal.service';
import { CreateTerminal, DeleteTerminal, LoadTerminals, UpdateTerminal } from '@shared/store/terminal/terminal.actions';
import { Terminal } from '@shared/models/terminal.model';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import {Injectable} from '@angular/core';

export class TerminalStateModel {
  terminals: Terminal[];
  loading: boolean;
}

@State<TerminalStateModel>({
  name: 'terminal',
  defaults: {
    terminals: [],
    loading: false,
  }
})

@Injectable()
export class TerminalState {

  @Selector()
  static terminals(state: TerminalStateModel) {
    return state.terminals;
  }

  @Selector()
  static loading(state: TerminalStateModel) {
    return state.loading;
  }

  constructor(private terminalService: TerminalService) {}

  @Action(LoadTerminals, { cancelUncompleted: true })
  loadTerminals({ patchState }: StateContext<TerminalStateModel>) {
    patchState({ loading: true });

    return this.terminalService.getAll()
      .pipe(
        tap((result) => patchState({
          terminals: result.data,
          loading: false,
        }))
      );
  }

  @Action(CreateTerminal)
  createExternalCompanyCategory({ patchState, setState }: StateContext<TerminalStateModel>, { payload }: CreateTerminal) {
    patchState({ loading: true });

    return this.terminalService.create(payload)
      .pipe(
        tap((result) => {
          setState(patch<TerminalStateModel>({
            terminals: append([result.data]),
            loading: false,
          }))
        })
      );
  }

  @Action(UpdateTerminal)
  updateExternalCompanyCategory({ patchState, setState }: StateContext<TerminalStateModel>, { id, payload }: UpdateTerminal) {
    patchState({ loading: true });

    return this.terminalService.update(id, payload)
      .pipe(
        tap((result) => {
          setState(patch<TerminalStateModel>({
            terminals: updateItem((category) => category.id === id, result.data),
            loading: false,
          }))
        })
      );
  }

  @Action(DeleteTerminal)
  deleteExternalCompanyCategory({ patchState, setState }: StateContext<TerminalStateModel>, { id }: DeleteTerminal) {
    patchState({ loading: true });

    return this.terminalService.delete(id)
      .pipe(
        tap(() => {
          setState(patch<TerminalStateModel>({
            terminals: removeItem((category) => category.id === id),
            loading: false,
          }))
        })
      );
  }
}
