import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Terminal } from '@shared/models/terminal.model';
import { TerminalActivity } from '@shared/models/terminal-activity.model';
import { AssetService } from '@shared/services/asset.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'saf-terminal-page-schooling-view',
  templateUrl: './terminal-page-schooling-view.component.html',
  styleUrls: ['./terminal-page-schooling-view.component.scss']
})
export class TerminalPageSchoolingViewComponent implements OnInit {
  @Input() terminal: Terminal;
  @Input() activity: TerminalActivity;
  @Output() viewCompleted = new EventEmitter();

  constructor() {}

  ngOnInit() {

  }

  onClickNext() {
    this.viewCompleted.emit();
  }
}
