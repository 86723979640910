import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ApiService} from '@shared/services/api.service';
import {DataResponse} from '@shared/models/response.model';
import {UserDetails} from '@shared/models/user-details.model';
import {CacheBuster} from 'ts-cacheable';

const cacheNotifier: Subject<void> = new Subject();
@Injectable()
export class UserDetailsService {

  constructor(private api: ApiService) {
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  getByUserId(userId: number): Observable<DataResponse<UserDetails>> {
    return this.api.get(`/userDetails`, { userId });
  }

  getByEmployeeId(employeeId: number): Observable<DataResponse<UserDetails>> {
    return this.api.get(`/userDetails`, { employeeId });
  }

  create(data: object): Observable<DataResponse<UserDetails>> {
    return this.api.post(`/userDetails`, data);
  }

  update(userId: number, data: object): Observable<DataResponse<UserDetails>> {
    return this.api.put(`/userDetails/${userId}`, data);
  }
}
