<div class="container" fxLayout="column" fxLayoutGap="16px" fxLayoutGap.xs="8px">
  <section class="header" fxLayout="row" fxLayoutGap="16px" fxLayoutGap.xs="8px">
    <button class="accident" mat-raised-button color="primary" (click)="openCreateDialog()">
      <mat-icon>add</mat-icon>
      <span>{{ 'add' | translate }}</span>
    </button>

    <saf-filter-panel [placeholder]="('enterSearchTermTitle' | translate)" (filterChange)="applyDrawerFilters($event)"></saf-filter-panel>
  </section>
  <mat-card class="content">
    <div class="table-container" [fxShow]="dataSource.data.length">
      <table mat-table [dataSource]="dataSource" class="table" matSort matSortDirection="asc" matSortActive="name" [trackBy]="trackById">

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="name">{{ 'title' | translate }}</th>
          <td mat-cell *matCellDef="let accidentCause">{{ accidentCause.name }}</td>
        </ng-container>

        <ng-container matColumnDef="editable">
          <th mat-header-cell *matHeaderCellDef>{{ 'editable' | translate }}</th>
          <td mat-cell *matCellDef="let accidentCause">
            <mat-icon class="{{accidentCause.editAble ? 'colorGreen' : 'colorRed'}}" matTooltip="{{ (accidentCause.editAble ? 'editable' : 'notEditable') | translate }}">{{ accidentCause.editAble ? 'check' : 'clear' }}</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef>{{ 'options' | translate }}</th>
          <td mat-cell *matCellDef="let accidentCause">
            <button mat-icon-button (click)="openUpdateDialog($event, accidentCause)" matTooltip="{{(accidentCause.editAble ? 'edit' : 'notEditable') | translate}}">
              <mat-icon class="{{accidentCause.editAble ? 'editIcon' : 'disabledIcon'}}">edit</mat-icon>
            </button>
            <button mat-icon-button (click)="openDeleteDialog($event, accidentCause)" matTooltip="{{(accidentCause.editAble ? 'delete' : 'notDeletable') | translate}}">
              <mat-icon class="{{accidentCause.editAble ? 'deleteIcon' : 'disabledIcon'}}">delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
        <tr mat-row class="selectable" *matRowDef="let row; columns: tableColumns;"
            (click)="openUpdateDialog($event, row)"></tr>
      </table>
      <mat-paginator class="mat-paginator-sticky" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                     [length]="totalCount" [showFirstLastButtons]="true"></mat-paginator>
    </div>
    <saf-empty-message *ngIf="!dataSource.data.length && !loading" (reloadClicked)="reloadTable()"></saf-empty-message>
    <saf-loading-spinner *ngIf="loading && !dataSource.data.length"></saf-loading-spinner>
    <saf-loading-overlay *ngIf="loading && dataSource.data.length"></saf-loading-overlay>
  </mat-card>
</div>

