import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import { ListResponse } from '@shared/models/response.model';
import { Cacheable } from 'ts-cacheable';
import { Module } from '@shared/models/module.model';

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
	providedIn: 'root'
})
export class ModuleService {

	constructor(private api: ApiService) {}

	@Cacheable({
		cacheBusterObserver: cacheNotifier
	})
	getList(params?: object): Observable<ListResponse<Module>> {
	return this.api.get(`/modules`, params);
	}

	@Cacheable({
		cacheBusterObserver: cacheNotifier
	})
	getModulesOfUser(userId: number): Observable<ListResponse<Module>> {
		return this.api.get(`/user/`+userId+'/modules');
	}

	static clearCache() {
		cacheNotifier.next();
	}
}
