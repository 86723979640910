<form [formGroup]="form" ngxsForm="terminalPage.addExternalCompanyForm" (ngSubmit)="onSubmit()">
  <p class="description">{{ 'pleaseEnterYourCompanyData' | translate }}</p>
  <div fxLayout="column">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'companyName' | translate }}</mat-label>
      <input type="text" matInput formControlName="name" required autocomplete="disabled">
    </mat-form-field>
    <ng-container *ngIf="!terminal.simpleView">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'address' | translate }}</mat-label>
        <input type="text" matInput formControlName="street" autocomplete="disabled">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'zipCode' | translate }}</mat-label>
        <input type="text" matInput formControlName="zipCode" autocomplete="disabled">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'city' | translate }}</mat-label>
        <input type="text" matInput formControlName="city" autocomplete="disabled">
      </mat-form-field>
    </ng-container>
  </div>
  <div fxLayoutAlign="end">
    <button type="submit" mat-raised-button color="primary">{{ 'next' | translate }}</button>
  </div>
</form>
