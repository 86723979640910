<form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="submitForm()">
  <div class="closeCross">
    <span mat-dialog-title>{{ 'createLocation' | translate }}</span>
    <mat-icon (click)="close()" class="cross">close</mat-icon>
  </div>
  <div mat-dialog-content class="block-fields">

    <mat-form-field appearance="outline">
      <mat-label>{{ 'title' | translate }}</mat-label>
      <input type="text" matInput formControlName="name" required>
    </mat-form-field>

    <saf-department-select placeholder="{{ 'company' | translate }}" [isCompany]="true" formControlName="companyId" required></saf-department-select>

    <saf-area-location-select [isFilter]="true" placeholder="{{ 'parentLocation' | translate }}" formControlName="parentLocationId"></saf-area-location-select>

    <saf-country-select formControlName="countryId" required></saf-country-select>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'city' | translate }}</mat-label>
      <input type="text" matInput formControlName="city" required>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'zipCode' | translate }}</mat-label>
      <input type="text" matInput formControlName="zipCode" required>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'street' | translate }}</mat-label>
      <input type="text" matInput formControlName="street" required>
    </mat-form-field>
  </div>
  <div mat-dialog-actions align="end">
    <button class="administration deleteButtonHover" mat-raised-button type="button" color="primary" (click)="close()">{{ 'cancel' | translate }}</button>
    <button class="administration saveButtonHover" mat-raised-button type="submit" color="primary">{{ 'save' | translate }}</button>
  </div>
</form>
<saf-loading-overlay *ngIf="loading"></saf-loading-overlay>
