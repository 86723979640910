import { UntypedFormGroup } from '@angular/forms';
import { InjectionToken, Injector } from '@angular/core';
import { PortalInjector } from '@angular/cdk/portal';
import { Department } from '@shared/models/department.model';
import * as moment from 'moment';
import { Moment } from 'moment';

const cryptoBuffer = new Uint8Array(36);

export const

  PORTAL_DATA = new InjectionToken<{}>('PortalData'),

  DEFAULT_PAGE_SIZE = new InjectionToken<number>('DefaultPageSize'),

  PAGE_SIZE_OPTIONS = new InjectionToken<number[]>('PageSizeOptions'),

  supportsTouch = ('ontouchstart' in window || !!navigator.maxTouchPoints),

  browserLanguage = navigator.language.split('-')[0].toLowerCase(),

  TIME_PATTERN = /^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,

  untilTodayDateFilter = (d: Moment): boolean => d.isSameOrBefore(getCurrentDate(), 'day'),

  noop = () => {},

  createPortalInjector = (injector: Injector, data = null) => new PortalInjector(injector, new WeakMap([[PORTAL_DATA, data]])),

  getTime = (timeString: string, format: string = 'HH:mm') => {
    return moment(timeString, 'HH:mm:ss').format(format);
  },

  getDate = (date) => {
    if (!date || date.indexOf('0000-00-00') !== -1) {
      return null;
    }

    return moment(date).toDate();
  },

  getMoment = (date) => {
    return moment(date);
  },

  getCurrentDate = () => {
    return getMoment(Date.now());
  },

  getFormattedDate = (date, format = 'YYYY-MM-DD') => {
    return moment(date).format(format);
  },

  getFormattedTime = (date, format = 'HH:mm') => {
    return moment(date).format(format);
  },

  getCurrentDateString = (format: string = 'YYYY-MM-DD') => {
    return getFormattedDate(Date.now(), format);
  },

  getCurrentTimeString = (format: string = 'HH:mm') => {
    return getFormattedTime(Date.now(), format);
  },
  getDirtyState = (formGroup: UntypedFormGroup): object => Object.keys(formGroup.controls).reduce<object>((state, name) => {
    const control = formGroup.controls[name];

    if (!control.dirty) {
      return state;
    }

    return {
      ...state,
      [name]: (control instanceof UntypedFormGroup) ? getDirtyState(control) : control.value,
    };
  }, {}),

  killEvent = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
  },

  getCleanFileName = (fileName: string) => fileName.substring(0, fileName.lastIndexOf('.')),

  hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    } : null;
  },

  getContrast = (hex: string): number => {
    const color = hexToRgb(hex);

    return color ? Math.round(((color.r * 299) + (color.g * 587) + (color.b * 114)) / 1000) : 255;
  },

  sortDepartments = (departments: Department[]): Department[] => departments.sort((a, b) => {
    const numberA = parseFloat(a.numberPath),
      numberB = parseFloat(b.numberPath);

    if (numberA < numberB) {
      return -1;
    }

    if (numberA > numberB) {
      return 1;
    }

    return 0;
  }),

  uuid = () => {
    crypto.getRandomValues(cryptoBuffer);

    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c: any, i: number) => (c ^ cryptoBuffer[i] & 15 >> c / 4).toString(16));
  },

  isDisplayMode = (displayMode: string) => window.matchMedia(`(display-mode: ${displayMode})`).matches,

  parseJSON = (string: string) => {
    try {
      return JSON.parse(string);
    } catch (e) {
      return null;
    }
  },

  findInvalidControls = (formGroup: UntypedFormGroup) => {
    const invalid = [];
    const controls = formGroup.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    console.log(invalid);
  },

  handleDepartmentUmlauts = (departments: any[]) => {
    const departmentsWithUmlauts = [];
    let str = '';
    departments.forEach((department) => {
      str = department.name;
      if (str.includes('&Auml;')) { str = str.replace('&Auml;', 'Ä'); }
      if (str.includes('&auml;')) { str = str.replace('&auml;', 'ä'); }
      if (str.includes('&Uuml;')) { str = str.replace('&Uuml;', 'Ü'); }
      if (str.includes('&uuml;')) { str = str.replace('&uuml;', 'ü'); }
      if (str.includes('&Ouml;')) { str = str.replace('&Ouml;', 'Ö'); }
      if (str.includes('&ouml;')) { str = str.replace('&ouml;', 'ö'); }
      if (str.includes('&szlig;')) { str = str.replace('&szlig;', 'ß'); }
      departmentsWithUmlauts.push(str);
    });
    return departmentsWithUmlauts.join(' -> ');
  },

  isMobileViewport = () => {
    return /(?=.*Android)(?=.*Mobile)|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };
