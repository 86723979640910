<mat-form-field [appearance]="appearance">
  <mat-label>{{ placeholder || ('templateState' | translate) }}</mat-label>
  <mat-select [(ngModel)]="value" [disabled]="disabled" [disableOptionCentering]="true" [required]="required">
    <mat-select-trigger [ngSwitch]="value">
      <mat-icon *ngSwitchCase="'N'" class="isAccessTimeIcon">access_time</mat-icon>
      <span *ngSwitchCase="'N'">{{ 'stateInProgress' | translate }}</span>
      <mat-icon *ngSwitchCase="'P'" class="isVisibilityIcon">visibility</mat-icon>
      <span *ngSwitchCase="'P'">{{ 'stateEditorialAudit' | translate }}</span>
      <mat-icon *ngSwitchCase="'C'" class="isVisibilityIcon">visibility</mat-icon>
      <span *ngSwitchCase="'C'">{{ 'stateSafetyOfficerAudit' | translate }}</span>
      <mat-icon *ngSwitchCase="'Y'" class="isCheckIcon">done</mat-icon>
      <span *ngSwitchCase="'Y'">{{ 'stateReleased' | translate }}</span>
    </mat-select-trigger>
    <mat-option value="N">
      <mat-icon class="isAccessTimeIcon">access_time</mat-icon>
      <span>{{ 'stateInProgress' | translate }}</span>
    </mat-option>
    <mat-option value="P">
      <mat-icon class="isVisibilityIcon">visibility</mat-icon>
      <span>{{ 'stateEditorialAudit' | translate }}</span>
    </mat-option>
    <mat-option value="C">
      <mat-icon class="isVisibilityIcon">visibility</mat-icon>
      <span>{{ 'stateSafetyOfficerAudit' | translate }}</span>
    </mat-option>
    <mat-option value="Y">
      <mat-icon class="isCheckIcon">done</mat-icon>
      <span>{{ 'stateReleased' | translate }}</span>
    </mat-option>
  </mat-select>
  <button mat-button *ngIf="hasValue" matSuffix mat-icon-button (click)="onClickClear($event)">
    <mat-icon class="deleteIcon">close</mat-icon>
  </button>
</mat-form-field>
