<mat-dialog-content>
  <div class="container" fxLayout="column" fxLayoutGap="16px" fxLayoutGap.xs="8px">
    <div class="closeCross">
      <span mat-dialog-title>{{config.placeholder}}</span>
      <mat-icon (click)="cancel()" class="cross">close</mat-icon>
    </div>
    <mat-card class="country-content">
      <section fxLayout="row" fxLayoutGap="16px">
        <saf-filter-panel [placeholder]="config.placeholderSearchTerm" (filterChange)="applyDrawerFilters($event)">
        </saf-filter-panel>
      </section>
      <div style="width: 100%">
        <h3 style="text-align: center; margin: 0">{{ config.placeholder }}</h3>
      </div>
      <mat-divider></mat-divider>
      <div [fxShow]="dataSource.data.length">
        <table mat-table [dataSource]="dataSource" class="table" matSort matSortActive="name"
               matSortDirection="asc" [trackBy]="trackById">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <mat-slide-toggle (click)="$event.stopPropagation()"
                                (change)="$event ? selectedRow(row, $event) : null"
                                [checked]="isSelected(row)">
              </mat-slide-toggle>
            </td>
          </ng-container>

          <ng-container matColumnDef="name" >
            <th class="titleFont" mat-header-cell *matHeaderCellDef style="text-align: center"></th>
            <td class="paddingLeft" mat-cell *matCellDef="let data">
              <mat-icon svgIcon="{{data.acronym.toLowerCase()}}" [ngStyle]="{'color': 'black'}" class="checkbox-icon"></mat-icon>
              <span>{{ data.name }}</span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true" style="display: none"></tr>
          <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
        </table>
        <mat-paginator class="mat-paginator-sticky" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                       [length]="totalCount" [showFirstLastButtons]="true"></mat-paginator>
      </div>
      <saf-empty-message *ngIf="!dataSource.data.length && !loading" (reloadClicked)="reloadTable()"></saf-empty-message>
      <saf-loading-spinner *ngIf="loading && !dataSource.data.length"></saf-loading-spinner>
      <saf-loading-overlay *ngIf="loading && dataSource.data.length"></saf-loading-overlay>
    </mat-card>
  </div>

</mat-dialog-content>
<div mat-dialog-actions align="end">
  <button mat-raised-button class="administration deleteButtonHover" color="primary" (click)="cancel()">{{ 'cancel' | translate }}</button>
  <button mat-raised-button class="administration saveButtonHover"  (click)="submit()" color="primary">{{ 'save' | translate }}</button>
</div>
