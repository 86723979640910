import {
  Component,
  OnInit,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart, PRIMARY_OUTLET,
  Router, RoutesRecognized
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { fadeInOutToggle } from '@shared/utils/animations';
import { AuthState } from '@shared/store/auth/auth.state';
import { Select, Store } from '@ngxs/store';
import { FrontendState } from '@frontend/store/frontend/frontend.state';
import { CloseSidenav, OpenSidenav, ToggleSidenav } from '@frontend/store/frontend/frontend.actions';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { BreakpointState } from '@shared/store/breakpoint/breakpoint.state';
import { Breakpoints } from '@shared/models/breakpoints.model';
import {NavigationComponent} from '@frontend/components/navigation/navigation.component';
import {Breadcrumb} from '@shared/models/breadcrumb.model';
import {ModuleName} from '@shared/models/module-name.model';
import {isMobileViewport} from '@shared/utils/utils';

const navigationEvents = [
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
];

@Component({
  selector: 'saf-frontend',
  templateUrl: './frontend.component.html',
  styleUrls: ['./frontend.component.scss'],
  animations: [fadeInOutToggle],
})
export class FrontendComponent implements OnInit {
  @Select(BreakpointState.currentBreakpoint) currentBreakpoint$;
  @Select(BreakpointState.breakpoints) breakpoints$;
  @Select(BreakpointState.isBreakpoint([Breakpoints.Medium, Breakpoints.Medium])) smallViewport$;
  @SelectSnapshot(BreakpointState.isBreakpoint([Breakpoints.XSmall, Breakpoints.Small])) smallViewport;
  public mobileViewport = false;
  @SelectSnapshot(AuthState.hasAssignedModules) hasAssignedModules;
  @SelectSnapshot(FrontendState.sidenavOpened) sidenavOpened;
  loading: boolean = false;
  public color;

  constructor(
    private router: Router,
    private store: Store,
    public route: ActivatedRoute,
    private navigation: NavigationComponent
  ) {}

  ngOnInit() {
    this.mobileViewport = isMobileViewport();
    this.getColor();
    this.router.events
      .pipe(filter((event) => navigationEvents.some((navigationEvent) => (event instanceof navigationEvent))))
      .subscribe((event) => {
        this.loading = (event instanceof NavigationStart);
        this.getColor();


        if (this.loading && this.smallViewport) {
          this.store.dispatch(new CloseSidenav());
        }
      });

    this.currentBreakpoint$.subscribe(() => {
      if (!this.hasAssignedModules) {
        return;
      }

      this.store.dispatch(this.smallViewport ? new CloseSidenav() : new OpenSidenav());
    });
  }

  toggleSidenav() {
    this.store.dispatch(new ToggleSidenav());
  }

  getColor() {
    if (this.router.url.includes('navigation')) {
     this.color = this.navigation.getFilteredCategories(this.route.snapshot.queryParamMap.get('navigation'))[0].color;
    }
    const routes = this.route.children;
    if (routes.length === 0) {
      return null;
    }

    for (const route of routes) {
      if (route.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      if (route.snapshot.data.color) {
        this.color = route.snapshot.data.color;
      }
    }
  }
}

