import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeMultiEditDialogComponent } from './employee-multi-edit-dialog.component';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { LoadingOverlayModule } from '@shared/components/loading-overlay/loading-overlay.module';
import { EmployeeSelectModule } from '@shared/components/employee-select/employee-select.module';
import { EmployeeCategorySelectModule } from '@shared/components/employee-category-select/employee-category-select.module';
import { DepartmentSelectModule } from '@shared/components/department-select/department-select.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ModuleSelectModule } from '@shared/components/module-select/module-select.module';
import { ReactiveFormsModule } from '@angular/forms';
import {CostCenterSelectModule} from '@shared/components/cost-center-select/cost-center-select.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        PipesModule,
        FlexLayoutModule,
        LoadingOverlayModule,
        EmployeeSelectModule,
        EmployeeCategorySelectModule,
        DepartmentSelectModule,
        ModuleSelectModule,
        ReactiveFormsModule,
        CostCenterSelectModule,
    ],
    declarations: [
        EmployeeMultiEditDialogComponent,
    ],
    exports: [
        EmployeeMultiEditDialogComponent,
    ]
})
export class EmployeeMultiEditDialogModule {}
