import { NgModule } from '@angular/core';
import { MaterialModule } from '@shared/material.module';
import { LoadingSpinnerComponent } from '@shared/components/loading-spinner/loading-spinner.component';

@NgModule({
  imports: [
    MaterialModule,
  ],
  declarations: [
    LoadingSpinnerComponent,
  ],
  exports: [
    LoadingSpinnerComponent,
  ],
})
export class LoadingSpinnerModule {}
