import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError, timer} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {RefreshLogin} from '@shared/store/auth/auth.actions';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(private store: Store,
              private router: Router) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if(error.status === 401) {
          this.store.dispatch(RefreshLogin)
            .pipe()
            .subscribe( () => {
              window.location.reload();
            });
        }
        return throwError(error.message);
      })
    );
  }
}
