import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { SearchInputComponent } from '../search-input/search-input.component';
import { FilterComponent } from '@shared/components/filter/filter.component';
import { FilterChange } from '@shared/models/filter-change.model';
import { debounceTime } from 'rxjs/operators';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
  selector: 'saf-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
  host: { 'class': 'filter' },
  providers: [{
    provide: FilterComponent,
    useExisting: forwardRef(() => SearchFilterComponent),
  }]
})
export class SearchFilterComponent {
  @ViewChild(SearchInputComponent, { static: true }) searchInput: SearchInputComponent;
  @Output() valueChange = new EventEmitter<FilterChange>();
  @Output() valueReset = new EventEmitter<string>();
  @Input() filterParam: string = 'fastSearch';
  @Input() preSearch: string = '';
  @Input() placeholder: string;
  @Input() autofocus: boolean = false;
  @Input() disabled: boolean = false;
  @Input() debounceTime: number = 500;

  ngOnInit() {
    this.searchInput.valueChange.pipe(debounceTime(this.debounceTime))
      .subscribe((text: string) => this.valueChange.emit({
        param: this.filterParam,
        value: text || null,
      }));
  }

  clear() {
    this.searchInput.clear();
    this.valueReset.emit(this.filterParam);
  }
}
