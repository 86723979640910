<form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="submitForm()">
  <div class="closeCross">
    <span mat-dialog-title>{{ (accidentCause.id ? 'editAccidentCause' : 'addAccidentCause') | translate }}</span>
    <mat-icon (click)="close()" class="cross">close</mat-icon>
  </div>
  <div mat-dialog-content class="block-fields">

    <mat-form-field appearance="outline">
      <mat-label>{{ 'title' | translate }}</mat-label>
      <input type="text" matInput required formControlName="name">
    </mat-form-field>

  </div>
  <div mat-dialog-actions align="end">
    <button class="administration deleteButtonHover" color="primary" mat-raised-button type="button" (click)="close()">{{ 'cancel' | translate }}</button>
    <button class="administration saveButtonHover" mat-raised-button type="submit" color="primary">{{ 'save' | translate }}</button>
  </div>
</form>
<saf-loading-overlay *ngIf="loading"></saf-loading-overlay>
