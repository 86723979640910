import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DataResponse, SuccessResponse, ListResponse } from '@shared/models/response.model';
import { ApiService } from '@shared/services/api.service';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { InstructionListItem } from '@shared/models/instruction-list-item.model';
import { Instruction } from '@shared/models/instruction.model';
import { InstructionDetails } from '@shared/models/instruction-details.model';
import { map } from 'rxjs/operators';

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class InstructionService {

  constructor(private api: ApiService) {}

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getList(params?: object): Observable<ListResponse<InstructionListItem>> {
    return this.api.get(`/instructions`, params);
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getDetailsList(params?: object): Observable<ListResponse<InstructionDetails>> {
    return this.api.get(`/instruction-list`, params);
  }

  getById(id: number): Observable<Instruction> {
    return this.api.get(`/instruction/${id}`);
  }

  getDetails(instructionId: number): Observable<InstructionDetails> {
    return this.api.get(`/instructionDetails/${instructionId}`)
      .pipe(map((details: InstructionDetails) => {
        details.instruction.blockSettings = Object.values(details.instruction.blockSettings);

        return details;
      }));
  }

  getPrintURL(instructionId: number, departmentId: number, jwt: string) {
    return `${this.api.apiURL}/instruction/${instructionId}/print?sectionId=${departmentId}&jwt=${jwt}`;
  }

  copy(instructionId: number, data: object): Observable<DataResponse<Instruction>> {
    return this.api.post(`/instruction/copy/${instructionId}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<Instruction>> {
    return this.api.post(`/instruction`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  createFromHazardAssessmentId(hazardAssessmentId: number, data: object): Observable<DataResponse<Instruction>> {
    return this.api.post(`/instruction/createFromAssessment/${hazardAssessmentId}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  createFromPatternInstructionId(data: object): Observable<DataResponse<Instruction>> {
    return this.api.post(`/instruction/createFromPattern`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<Instruction>> {
    return this.api.put(`/instruction/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  generateTopic(id: number): Observable<DataResponse<Instruction>> {
    return this.api.put(`/instruction/${id}/topic`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/instruction/${id}`);
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
