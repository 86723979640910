import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import { DataResponse, SuccessResponse, ListResponse } from '@shared/models/response.model';
import { Measure } from '@shared/models/measure.model';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { MeasureListItem } from '@shared/models/measure-list-item.model';
import {MeasureDetails} from '@shared/models/measure-detail.model';

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class MeasureService {

  constructor(private api: ApiService) {}

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getAll(params: object): Observable<ListResponse<Measure>> {
    return this.api.get(`/measures`, params);
  }

  @CacheBuster({
      cacheBusterNotifier: cacheNotifier
  })
  getList(params: object): Observable<ListResponse<MeasureListItem>> {
    return this.api.get(`/measure-list`, params);
  }

  getById(measureId: number): Observable<Measure> {
    return this.api.get(`/measure/${measureId}`);
  }

  getDetailById(measureId: number): Observable<MeasureDetails> {
    return this.api.get(`/measure-detail/${measureId}`);
  }

  getByHazardId(assessmentId: number): Observable<ListResponse<Measure>>  {
    return this.api.get(`/measure-hazard/${assessmentId}`);
  }
  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<Measure>> {
    return this.api.post(`/measure`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(measureId: number, data: object): Observable<DataResponse<Measure>> {
    return this.api.put(`/measure/${measureId}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  updateList(data: object): Observable<SuccessResponse> {
    return this.api.put(`/measures`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(measureId: number): Observable<SuccessResponse> {
    return this.api.delete(`/measure/${measureId}`);
  }

  getExportURL(filter: object, jwt: string) {
    return `${this.api.apiURL}/measures/export?filter=${JSON.stringify(filter)}&jwt=${jwt}`;
  }


  static clearCache() {
    cacheNotifier.next();
  }
}
