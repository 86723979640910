import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {AppConfigService} from '@shared/services/app-config.service';
import {browserLanguage, DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS} from '@shared/utils/utils';
import {registerLocaleData} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from '@shared/shared.module';
import {FrontendModule} from '@frontend/frontend.module';
import {AuthModule} from '@auth/auth.module';
import {PageNotFoundModule} from '@page-not-found/page-not-found.module';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '@env/environment';
import {StoreModule} from '@shared/store/store.module';
import {ServiceModule} from '@shared/services/service.module';
import * as moment from 'moment';
import {AppRoutingModule} from '@app/app-routing.module';
import {TerminalModule} from '@terminal/terminal.module';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {TreeModule} from '@circlon/angular-tree-component';
import {NavigationComponent} from '@frontend/components/navigation/navigation.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {RequestLimitInterceptor} from '@shared/interceptor/RequestLimitInterceptor';
import {Accident} from '@shared/models/accident.model';
import {AccidentCauseModule} from '@frontend/modules/accident-cause/accident-cause.module';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {UnauthorizedInterceptor} from '@shared/interceptor/UnauthorizedInterceptor';

@NgModule({
  imports: [
    NgMultiSelectDropDownModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    StoreModule,
    ServiceModule,
    AuthModule,
    FrontendModule,
    TerminalModule,
    AppRoutingModule,
    PageNotFoundModule,
    TreeModule,
    AccidentCauseModule,
  ],
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  providers: [
    NavigationComponent,
    AppConfigService,
    {
      provide: LOCALE_ID,
      useValue: browserLanguage,
    },
    {
      provide: DEFAULT_PAGE_SIZE,
      useValue: 15,
    },
    {
      provide: PAGE_SIZE_OPTIONS,
      useValue: [5, 15, 30, 50, 100],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfigService: AppConfigService) => {
        moment.locale(browserLanguage);
        return () => Promise.all([
          appConfigService.load().subscribe(),
        ]);
      },
      deps: [AppConfigService],
      multi: true,
    },
    {provide: HTTP_INTERCEPTORS, useClass: RequestLimitInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true},
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: {
        showDelay: 0,
        hideDelay: 0,
        touchendHideDelay: 1500,
        disableTooltipInteractivity: true
      }
    }

  ],
})
export class AppModule {
}
