import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { FilterChange } from '@shared/models/filter-change.model';
import { FilterComponent } from '@shared/components/filter/filter.component';
import { HazardAssessmentTemplateCategorySelectComponent } from '@shared/components/hazard-assessment-template-category-select/hazard-assessment-template-category-select.component';

@Component({
  selector: 'saf-hazard-assessment-template-category-filter',
  templateUrl: './hazard-assessment-template-category-filter.component.html',
  styleUrls: ['./hazard-assessment-template-category-filter.component.scss'],
  host: { 'class': 'filter' },
  providers: [{
    provide: FilterComponent,
    useExisting: forwardRef(() => HazardAssessmentTemplateCategoryFilterComponent),
  }]
})
export class HazardAssessmentTemplateCategoryFilterComponent {
  @ViewChild(HazardAssessmentTemplateCategorySelectComponent) select: HazardAssessmentTemplateCategorySelectComponent;
  @Output() valueChange = new EventEmitter<FilterChange>();
  @Output() valueReset = new EventEmitter<string>();
  @Input() filterParam: string = 'categoryIds';
  @Input() placeholder: string;

  onChange(value: string | string[]) {
    this.valueChange.emit({
      param: this.filterParam,
      value: value ? [value] : null,
    });
  }

  clear() {
    this.select.clear();
    this.valueReset.emit(this.filterParam);
  }
}
