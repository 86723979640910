import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import { BooleanResponse } from '@shared/models/response.model';
import {DataResponse, SuccessResponse} from '../models/response.model';

@Injectable()
export class UserService {

  constructor(private api: ApiService) {}

  getById(userId: number, params?: object): Observable<any> {
    return this.api.get(`/user/${userId}`, params);
  }

  changePassword(userId: number, params: { oldPass: string, newPass: string, newPassConfirm: string }): Observable<BooleanResponse> {
    return this.api.put(`/user/${userId}/changePassword`, params);
  }

  addContact(data: object): Observable<DataResponse<any>> {
    return this.api.post(`/contactForm`, data);
  }

}
