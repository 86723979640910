import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';
import {Cacheable, CacheBuster} from 'ts-cacheable';
import {DataResponse, ListResponse, SuccessResponse} from '../../models/response.model';
import {Location, LocationDetail} from '../../models/area/location.model';

const cacheNotifier: Subject<void> = new Subject();

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private api: ApiService) {
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getList(params?: object): Observable<ListResponse<Location>> {
    return this.api.get(`/locations`, params);
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getListDetail(params?: object): Observable<ListResponse<LocationDetail>> {
    return this.api.get(`/location-list`, params);
  }

  getById(id: number): Observable<Location> {
    return this.api.get(`/location/${id}`);
  }

  getDetail(id: number): Observable<LocationDetail> {
    return this.api.get(`/location-detail/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<Location>> {
    return this.api.post(`/location`, data);
  }

  update(id: number, data: object): Observable<DataResponse<Location>> {
    return this.api.put(`/location/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/location/${id}`);
  }

  getExportURL(filter: object, jwt: string) {
    return `${this.api.apiURL}/location-list/export?filter=${JSON.stringify(filter)}&jwt=${jwt}`;
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
