<mat-toolbar color="primary" [ngStyle]="{'background': headerColor? headerColor : ''}" class="header"
             [class.sidenav-closed]="!sidenavOpened || (smallViewport$ | async)">
  <mat-toolbar-row>
    <div *ngIf="!mobileViewport">
      <button mat-icon-button class="sidenav-toggle" (click)="toggleSidenav()" *ngIf="(hasAssignedModules$ | async) && (!sidenavOpened || (smallViewport$ | async))">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <div *ngIf="mobileViewport && _location.path() !== '/dashboard'">
      <button mat-icon-button (click)="locationBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <saf-breadcrumbs *ngIf="!mobileViewport"></saf-breadcrumbs>
    <span class="spacer" *ngIf="!mobileViewport"></span>
<!--    <button mat-icon-button matTooltip="{{ 'globalSearch' | translate }}" (click)="search.toggle()">-->
<!--      <mat-icon>search</mat-icon>-->
<!--    </button>-->
<!--    <saf-notification-menu></saf-notification-menu>-->
    <mat-icon class="system-user-warning" *ngIf="systemUser" [matTooltip]="('loggedInAsSystemUserMsg' | translate)">priority_high</mat-icon>
    <saf-user-panel [showToggle]="false" [ngStyle]="{'background': headerColor? headerColor : ''}"></saf-user-panel>

    <span class="spacer" *ngIf="mobileViewport"></span>

    <button mat-icon-button *ngIf="!mobileViewport" matTooltip="{{ 'supportPortal' | translate }}" (click)="openInfoboxDialog()">
      <mat-icon>help_center</mat-icon>
    </button>
    <button mat-icon-button matTooltip="{{ 'moreActions' | translate }}" [matMenuTriggerFor]="headerMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <saf-search-bar #search></saf-search-bar>
  </mat-toolbar-row>
</mat-toolbar>

<mat-menu #headerMenu="matMenu">
  <a mat-menu-item routerLink="/settings">
    <mat-icon>settings</mat-icon>
    <span>{{ 'personalSettings' | translate }}</span>
  </a>
  <a  mat-menu-item routerLink="/translations">
    <mat-icon>translate</mat-icon>
    <span>{{ 'translations' | translate }}</span>
  </a>
  <a *ngIf="mobileViewport" mat-menu-item (click)="openInfoboxDialog()">
    <mat-icon>help_center</mat-icon>
    <span>{{ 'supportPortal' | translate }}</span>
  </a>
  <a mat-menu-item routerLink="/logout">
    <mat-icon>exit_to_app</mat-icon>
    <span>{{ 'logout' | translate }}</span>
  </a>
<!--  <a mat-menu-item (click)="$event.stopPropagation()">-->
<!--    <mat-icon fontSet="material-symbols-outlined">dark_mode</mat-icon>-->
<!--    <span style="margin-right: 16px">{{ 'theme' | translate }}</span>-->
<!--    <mat-slide-toggle (change)="changeTheme($event)" [checked]="formGroup.get('theme').value.name === 'dark'"></mat-slide-toggle>-->
<!--  </a>-->
</mat-menu>
