import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import {Cacheable, CacheBuster} from 'ts-cacheable';
import { DataResponse, ListResponse, SuccessResponse } from '@shared/models/response.model';
import { ExternalCompanyEmployee } from '@shared/models/external-company-employee.model';
import { ExternalCompanyEmployeeHistory } from '@shared/models/external-company-employee-history.model';
import { ExternalCompanyEmployeeDetails } from '@shared/models/external-company-employee-details.model';

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class ExternalCompanyEmployeeService {

  constructor(private api: ApiService) {}

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getAll(params?: object): Observable<ListResponse<ExternalCompanyEmployee>> {
    return this.api.get(`/external/employees`, params);
  }

  getById(id): Observable<ExternalCompanyEmployee> {
    return this.api.get(`/external/employee/${id}`);
  }

  getDetails(id): Observable<ExternalCompanyEmployeeDetails> {
    return this.api.get(`/external/employeeDetail/${id}`);
  }

  getHistory(id): Observable<ListResponse<ExternalCompanyEmployeeHistory>> {
    return this.api.get(`/external/employee/${id}/history`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<ExternalCompanyEmployee>> {
    return this.api.post(`/external/employee`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<ExternalCompanyEmployee>> {
    return this.api.put(`/external/employee/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/external/employee/${id}`);
  }

  getExportURL(filter: object, jwt: string) {
    return `${this.api.apiURL}/external/employees/export?filter=${JSON.stringify(filter)}&jwt=${jwt}`;
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
