import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'saf-infobox-dialog',
  templateUrl: './infobox-dialog.component.html',
  styleUrls: ['./infobox-dialog.component.scss']
})
export class InfoboxDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<InfoboxDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  close(result: boolean = false) {
    this.dialogRef.close(result);
  }

  mailTo() {
    window.location.href = 'mailto:support@safety-doors.de';
  }

  openSupportPortal() {
    window.open('https://desire-solution.freshdesk.com');
  }
}
