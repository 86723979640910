import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@shared/material.module';
import { QuillModule } from 'ngx-quill';
import { WysiwygEditorComponent } from '@shared/components/wysiwyg-editor/wysiwyg-editor.component';
import { PipesModule } from '@shared/pipes/pipes.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PipesModule,
    QuillModule.forRoot(),
  ],
  declarations: [
    WysiwygEditorComponent,
  ],
  exports: [
    WysiwygEditorComponent,
  ],
})
export class WysiwygEditorModule {}
