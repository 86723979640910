<form *ngIf="formGroup" [formGroup]="formGroup" (submit)="submit()">
  <span class="center-header" mat-dialog-title>{{ 'forgetPasswordDescription'| translate }}</span>
  <span>{{ 'resetPasswordMsg'| translate }}</span>
  <div mat-dialog-content class="block-fields">

    <mat-form-field appearance="outline">
      <mat-label>{{ 'email' | translate }}</mat-label>
      <input type="text" matInput required formControlName="mail">
    </mat-form-field>

  </div>
  <div class="button-position" mat-dialog-actions fxLayoutAlign="center">
    <button color="primary" class="administration deleteButtonHover" mat-raised-button type="button" (click)="close()">{{ 'cancel' | translate }}</button>
    <button class="administration saveButtonHover" mat-raised-button type="submit" color="primary">{{ 'Versenden' }}</button>
  </div>
</form>
<saf-loading-overlay *ngIf="loading"></saf-loading-overlay>
