import {AfterViewInit, Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {filter, tap} from 'rxjs/operators';
import {LocationDetail} from '@shared/models/area/location.model';
import {LocationService} from '@shared/services/area/location.service';
import {AreaLocationSelectDialogComponent} from '@shared/components/area-location-select-dialog/area-location-select-dialog.component';
import {TranslationService} from "@shared/services/translation.service";
import {PermissionService} from "@shared/services/permission.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {
  AreaLocationSelectAddComponent
} from "@shared/components/area-location-select-add/area-location-select-add.component";

@Component({
  selector: 'saf-area-location-select',
  templateUrl: './area-location-select.component.html',
  styleUrls: ['./area-location-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AreaLocationSelectComponent),
      multi: true
    }
  ],
})
export class AreaLocationSelectComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  _valueText: string = '';
  private locationDetail: LocationDetail;
  public hasPermission = false;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) { this._required = coerceBooleanProperty(value); }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled || this._loading; }
  set disabled(value: boolean) { this._disabled = coerceBooleanProperty(value); }
  public _disabled = false;

  @Input()
  get multiple(): boolean { return this._multiple; }
  set multiple(value: boolean) { this._multiple = coerceBooleanProperty(value); }
  private _multiple = false;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) { this._placeholder = value; }
  private _placeholder: string;

  @Input()
  get appearance(): MatFormFieldAppearance { return this._appearance; }
  set appearance(value: MatFormFieldAppearance) { this._appearance = value; }
  private _appearance: MatFormFieldAppearance = 'outline';

  @Input()
  get value(): any { return this._value; }
  set value(value: any) { this.writeValue(value); }
  private _value: any = null;

  @Input()
  get isFilter(): boolean { return this._isFilter; }
  set isFilter(value: boolean) { this._isFilter = coerceBooleanProperty(value); }
  private _isFilter = false;

  get loading(): boolean { return this._loading; }
  private _loading: boolean = false;

  get hasValue(): boolean {
    if (this.disabled === false) {
      return !!this._valueText;
    } else {
      return false;
    }
  }

  get hasNoValue(): boolean {
    if (this._valueText === '' || this.disabled === true) {
      return true;
    }
  }

  @Output() valueChange = new EventEmitter<any>();

  onChange: Function = () => {};
  onTouched: Function = () => {};

  constructor(
    private locationService: LocationService,
    private dialog: MatDialog,
    private translationService: TranslationService,
    private permissionService: PermissionService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.hasPermission = true;
  }

  ngAfterViewInit() {
    this.loadById(this.value);
  }

  registerOnChange(onChange: Function) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: Function) {
    this.onTouched = onTouched;
  }

  writeValue(value: any) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
    this.valueChange.emit(value);
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  loadById(id?: number) {
    if (id) {
      this._loading = true;

      this.locationService.getDetail(id)
        .pipe(tap(() => this._loading = false))
        .subscribe((response) => {
          this._valueText = response.location.name;
          this.locationDetail = response;
        });
    }
  }

  openLocationSelectDialog(e) {
    const dialogConfig: MatDialogConfig = {
      width: '650px',
      disableClose: true,
      data: {
        multiple: this.multiple,
        selected: this.locationDetail ? this.locationDetail : null,
      }
    };

    this._loading = true;
    this.dialog.open(AreaLocationSelectDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(tap(() => this._loading = false))
      .subscribe((result) => {
        if (!result) {
          return;
        }
        if (result.length === 0) {
          this.value = null;
          this._valueText = '';
          this.locationDetail = null;
        } else {
          this.value = this.multiple
            ? result.map(locationDetail => locationDetail.location.id)
            : result.map(locationDetail => locationDetail.location.id)[0];
          this._valueText = this.multiple
            ? result.map((locationDetail) => locationDetail.location.name).join(', ')
            : result.map(locationDetail => locationDetail.location.name)[0];
          this.locationDetail = result;
        }
      });
  }

  openDialog() {
    if (this.hasPermission) {
      this.dialog.open(AreaLocationSelectAddComponent)
        .afterClosed()
        .pipe(filter((success) => success))
        .subscribe(result => {
          if (result.result) {
            this.locationService.getDetail(result.data.id)
              .pipe(tap(() => this._loading = false))
              .subscribe((response) => {
                this.value = response.location.id;
                this._valueText = response.location.name;
                this.locationDetail = response;
              });
          }
        });
    } else {
      this.returnPermissionError();
    }
  }

  clear() {
    this.value = null;
    this._valueText = '';
    this.locationDetail = null;
  }

  onClickClear(event) {
    this.clear();
    event.stopImmediatePropagation();
  }

  returnPermissionError() {
    return this.snackBar.open(this.translationService.translate('permissionError'));
  }
}
