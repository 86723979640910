import {Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'saf-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchInputComponent),
      multi: true
    }
  ],
})
export class SearchInputComponent implements ControlValueAccessor {

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) { this._required = coerceBooleanProperty(value); }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) { this._disabled = coerceBooleanProperty(value); }
  private _disabled = false;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) { this._placeholder = value; }
  private _placeholder: string;

  @Input()
  get appearance(): MatFormFieldAppearance { return this._appearance; }
  set appearance(value: MatFormFieldAppearance) { this._appearance = value; }
  private _appearance: MatFormFieldAppearance = 'outline';

  @Input()
  get value(): string { return this._value; }
  set value(value: string) { this.writeValue(value); }
  private _value: string = '';

  @Input()
  get clearIcon(): boolean { return this._clearIcon; }
  set clearIcon(value: boolean) { this._clearIcon = coerceBooleanProperty(value); }
  private _clearIcon = true;

  get showClearButton(): boolean {
    return this.clearIcon && this.hasValue && !this.disabled && !this.required;
  }

  get hasValue(): boolean {
    return !!this.value;
  }

  @Input() autofocus: boolean = true;
  @Output() valueChange = new EventEmitter<string>();

  @ViewChild(MatInput) input: MatInput;

  @ViewChild('hiddenText') textEl: ElementRef;

  minWidth: number = 64;
  width: number = this.minWidth;

  // resize() {
  //   setTimeout(() => this.width = Math.max(this.minWidth, this.textEl.nativeElement.offsetWidth));
  // }

  onChange: Function = () => {};
  onTouched: Function = () => {};

  registerOnChange(onChange: Function) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: Function) {
    this.onTouched = onTouched;
  }

  writeValue(value: any) {
    if (value !== this._value) {
      this._value = value;
      this.onChange(value);
      this.onTouched();
      this.valueChange.emit(value);
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  clear() {
    this.value = '';
  }

  onClickClear(event) {
    this.clear();
    event.stopImmediatePropagation();
  }
}
