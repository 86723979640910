<mat-form-field [appearance]="appearance">
  <mat-label>{{ placeholder || ((multiple ? 'countires' : 'country') | translate) }}</mat-label>
  <input matInput
         [(ngModel)]="_valueText"
         [required]="required"
         [readonly]="true"
         [multiple]="multiple"
  />
  <button *ngIf="hasNoValue || required" type="button" mat-icon-button matSuffix (click)="openCountrySelectDialog($event)">
    <mat-icon class="iconColor">language</mat-icon>
  </button>
  <div *ngIf="showClearButton && hasValue &&!hasNoValue"  matSuffix>
    <button type="button" mat-icon-button  (click)="openCountrySelectDialog($event)">
      <mat-icon class="iconColor">language</mat-icon>
    </button>
    <button type="button" mat-icon-button (click)="onClickClear($event)">
      <mat-icon class="deleteIcon">close</mat-icon>
    </button>
  </div>
</mat-form-field>
