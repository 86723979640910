import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import {Cacheable, CacheBuster} from 'ts-cacheable';
import { DataResponse, SuccessResponse, ListResponse } from '@shared/models/response.model';
import { HazardAssessmentTemplateCategory } from '@shared/models/hazard-assessment-template-category.model';

const cacheNotifier: Subject<void> = new Subject();
@Injectable({
  providedIn: 'root'
})
export class HazardAssessmentTemplateCategoryService {

  constructor(private api: ApiService) {}

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getList(params?: object): Observable<ListResponse<HazardAssessmentTemplateCategory>> {
    return this.api.get(`/hazardAssessmentTemplateCategories`, params);
  }

  getById(id: number): Observable<HazardAssessmentTemplateCategory> {
    return this.api.get(`/hazardAssessmentTemplateCategory/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<HazardAssessmentTemplateCategory>> {
    return this.api.post(`/hazardAssessmentTemplateCategory`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  update(id: number, data: object): Observable<DataResponse<HazardAssessmentTemplateCategory>> {
    return this.api.put(`/hazardAssessmentTemplateCategory/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/hazardAssessmentTemplateCategory/${id}`);
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
