import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { TerminalPageState } from '@terminal/store/terminal-page/terminal-page.state';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { Terminal } from '@shared/models/terminal.model';
import { TerminalActivity, TerminalActivityStatus, TerminalActivityType } from '@shared/models/terminal-activity.model';
import { TitleService } from '@shared/services/title.service';
import { takeUntil } from 'rxjs/operators';
import { SaveActivity, LoadSelectedTerminal, PollActivity } from '@terminal/store/terminal-page/terminal-page.actions';

type ViewType = 'loading' | 'poll-activity'| 'select-language' | 'add-external-company' | 'add-external-employee' | 'confirm-external-employee' | 'schooling';

@Component({
  selector: 'saf-terminal-page',
  templateUrl: './terminal-page.component.html',
  styleUrls: ['./terminal-page.component.scss']
})
export class TerminalPageComponent implements OnInit, OnDestroy {
  @Select(TerminalPageState.terminal) terminal$;
  @Select(TerminalPageState.activity) activity$;
  @Select(TerminalPageState.loading) loading$;
  onDestroy$ = new Subject<void>();

  currentTerminal: Terminal;
  currentActivity: TerminalActivity;
  currentView$ = new BehaviorSubject<ViewType>('loading');
  activityTimeoutDuration = 1000 * 60 * 15;
  activityPollInterval = 5000;
  activityTimeoutId = null;

  constructor(
    private store: Store,
    private titleService: TitleService,
  ) {}

  ngOnInit() {
    this.terminal$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((terminal) => this.setTerminal(terminal));

    this.store.dispatch(new LoadSelectedTerminal());
  }

  setTerminal(terminal: Terminal) {
    if (!terminal) {
      return;
    }

    this.currentTerminal = terminal;
    this.titleService.setTitle(terminal.name);
    this.startActivityPolling();
  }

  setView(view: ViewType) {
    if (this.currentView$.getValue() !== view) {
      this.currentView$.next(view);
    }
  }

  startActivityPolling() {
    this.activity$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((activity) => this.setActivity(activity));

    timer(0, this.activityPollInterval)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.store.dispatch(new PollActivity({ terminalId: this.currentTerminal.id })));

    this.setView('poll-activity');
  }

  setActivity(activity: TerminalActivity) {
    if (activity === this.currentActivity) {
      return;
    }

    if (this.currentActivity && activity && this.currentActivity.id === activity.id) {
      return;
    }

    this.currentActivity = activity;

    if (this.currentActivity) {
      this.activityTimeoutId = setTimeout(() => this.timeoutActivity(), this.activityTimeoutDuration);

      if (this.currentTerminal.selectLanguage) {
        this.setView('select-language');
        return;
      }
    }

    this.showCurrentActivityView();
  }

  showCurrentActivityView() {
    if (!this.currentActivity) {
      this.setView('poll-activity');
      return;
    }

    switch (this.currentActivity.activity) {
      case TerminalActivityType.ADD_EXTERNAL_EMPLOYEE:
        this.setView('add-external-employee');
        break;
      case TerminalActivityType.CONFIRM_AND_SCHOOLING:
        this.setView('confirm-external-employee');
        break;
      case TerminalActivityType.ADD_EXTERNAL_COMPANY:
        this.setView('add-external-company');
        break;
    }
  }

  onLanguageSelected() {
    this.showCurrentActivityView();
  }

  onCompanyCreated() {
    this.setView('add-external-employee');
  }

  onEmployeeCreated() {
    this.setView('schooling');
  }

  onEmployeeConfirmed() {
    this.setView('schooling');
  }

  onSchoolingDone() {
    this.finishActivity();
  }

  finishActivity() {
    this.updateActivity(TerminalActivityStatus.DONE);
  }

  cancelActivity() {
    this.updateActivity(TerminalActivityStatus.CANCELLED);
  }

  timeoutActivity() {
    this.activityTimeoutId = null;
    this.updateActivity(TerminalActivityStatus.TIMEOUT);
  }

  updateActivity(status) {
    if (this.activityTimeoutId) {
      clearTimeout(this.activityTimeoutId);
      this.activityTimeoutId = null;
    }

    this.store.dispatch(new SaveActivity(status));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
