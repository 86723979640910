import { Injectable } from '@angular/core';
import {AppConfigService} from './app-config.service';

@Injectable()
export class AssetService {

  get apiURL() {
    return this.appConfigService.appConfig.apiURL;
  }

  constructor(private appConfigService: AppConfigService) {}

  getAssetURL(path?: string) {
    return path ? `${this.apiURL}${path}` : '';
  }

  getHazardGroupImage(imagePath: string) {
    if (!imagePath || imagePath === 'default/no_icon.png') {
      return '';
    }

    if (imagePath.startsWith('/uploads')) {
      return this.getAssetURL(imagePath);
    }

    return this.getAssetURL(`/uploads/gbuRisc/${imagePath}`);
  }

  getHazardIconImage(type: string, imagePath: string) {
    if (!type || !imagePath) {
      return '';
    }

    return this.getAssetURL(`/uploads/hazardIcons/${type}/${imagePath}`);
  }
}
